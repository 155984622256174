import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { UrlService } from '../../model/url.service';
import { SugarService } from '../../model/sugar.service';
import { GlobalService } from '../../model/global.service';
import { ArtistNamePipe } from '../artsaas-pipes/artist-name.pipe';
import { ArtistDatesPipe } from '../artsaas-pipes/artist-dates.pipe';
import { TranslatePipe } from '../translate-pipes/translate.pipe';
import { ReplacePlaceholderPipe } from '../translate-pipes/replace-placeholder.pipe';
import { UniversalPlatformService } from '../platform/universal-platform.service';
import { Utils } from '../utils.class';
import { ObjectUtils } from '../utils/object-utils';
import { UrlParams, UrlUtils } from '../utils/url-utils';
import { maybeFixDates } from '../date-functions';

export declare interface PrevNextContent {
  id: number,
  slug: string,
  link: string | string[],
  routerLink: string | string[],
  url: string,
  query: null | UrlParams,
  parent: null | string,
}

export declare interface PrevNextItem {
  prev: PrevNextContent,
  next: PrevNextContent,
}

export declare interface SanitizedBanner {
  type: 'html' | 'embed' | 'image' | 'empty',
  thumbnails: null | BannerThumbnails,
  content: string | SafeValue | BannerEmbedUrl | null,
}

declare interface BannerThumbnails {
  detail: string,
  slideshow: string,
  miniature: string,
  medium: string,
  original: string,
}

declare interface BannerEmbedUrl {
  [key: string]: string | null,
}

export class Sanitizer {

  protected sanitizer: DomSanitizer;

  constructor(
    sanitizer: DomSanitizer,
  ) {
    this.sanitizer = sanitizer;
  }

  createSanitizedFields(item: { [key: string]: any }, sanitizedProps: string[], sanitizationType?: 'html' | 'url' | 'style' | 'script' | 'resource'): { [key: string]: any } {
    let propName, propValue;
    let sanitized: { [key: string]: any } | string;
    let sPropName: string;
    let pi = 0;

    for (; pi < (sanitizedProps || []).length; pi++) {
      propName = sanitizedProps[pi];

      if (!item.hasOwnProperty(propName)) {
        continue;
      }

      propValue = item[propName] ? item[propName] : null;
      sPropName = propName + '_sanitized';
      sanitized = this.sanitize(propValue, sanitizationType);

      item[sPropName] = sanitized;
    }

    return item;
  }

  sanitize(
    value: string | { [key: string]: string },
    sanitizationType: 'html' | 'url' | 'style' | 'script' | 'resource' = 'html',
  ): SafeValue | string | { [key: string]: SafeValue | string } {
    if (!sanitizationType) {
      sanitizationType = 'html';
    }

    if (typeof value === 'string') {
      return this.sanitizeValue(value, sanitizationType);
    }

    const sanitizedValue = {};

    for (const prop in value) {
      if (!value.hasOwnProperty(prop)) {
        continue;
      }

      sanitizedValue[prop] = value[prop]
        ? this.sanitizeValue(value[prop], sanitizationType)
        : '';
    }

    return sanitizedValue;
  }

  private sanitizeValue(value: string, sanitizationType: 'html' | 'url' | 'style' | 'script' | 'resource'): SafeValue | string {
    let sanitizedValue: SafeValue | string = '';

    if (!value) {
      return sanitizedValue;
    }

    switch (sanitizationType) {
      case 'html':
        sanitizedValue = this.sanitizer.bypassSecurityTrustHtml(value);
        break;

      case 'url':
        sanitizedValue = this.sanitizer.bypassSecurityTrustUrl(value);
        break;

      case 'style':
        sanitizedValue = this.sanitizer.bypassSecurityTrustStyle(value);
        break;

      case 'script':
        sanitizedValue = this.sanitizer.bypassSecurityTrustScript(value);
        break;

      case 'resource':
        sanitizedValue = this.sanitizer.bypassSecurityTrustResourceUrl(value);
        break;
    }

    return sanitizedValue;
  }

}

/**
 * --- element kolekcji ---
 */
export abstract class ApiCollectionItemSetter {

  protected urlService: UrlService;
  protected globalService: GlobalService;
  protected platformService: UniversalPlatformService;

  protected sanitizer: DomSanitizer;

  constructor(
    urlService: UrlService,
    globalService: GlobalService,
    platformService: UniversalPlatformService,
    sanitizer: DomSanitizer,
  ) {
    this.urlService = urlService;
    this.globalService = globalService;
    this.platformService = platformService;
    this.sanitizer = sanitizer;
  }

  abstract setup(item: { [key: string]: any }, isCopy: boolean): { [key: string]: any };

  setupLink(item: { [key: string]: any }): { [key: string]: string } {
    if (typeof item.link === 'undefined') {
      item.link = {};
    }

    const link: { [key: string]: string } = item.link;
    const allowedLangs = this.urlService.allowedLangs;

    let li = 0;

    for (; li < (allowedLangs || []).length; ++li) {
      const langCode = allowedLangs[li].code;

      link[langCode] = this.urlService.link(
        this.getEndpoint(item),
        item,
      );
    }

    return link;
  }

  abstract getEndpoint(item: { [key: string]: any }): string;

}

// element kolekcji: news
export class NewsColletionItemSetter extends ApiCollectionItemSetter {

  protected artistNamePipe: ArtistNamePipe;
  protected artistDatesPipe: ArtistDatesPipe;

  constructor(
    urlService: UrlService,
    globalService: GlobalService,
    platformService: UniversalPlatformService,
    sanitizer: DomSanitizer,
    artistNamePipe: ArtistNamePipe,
    artistDatesPipe: ArtistDatesPipe,
  ) {
    super(
      urlService,
      globalService,
      platformService,
      sanitizer,
    );

    this.artistNamePipe = artistNamePipe;
    this.artistDatesPipe = artistDatesPipe;
  }

  setup(rawItem: { [key: string]: any }, isCopy: boolean): { [key: string]: any } {
    const item: { [key: string]: any } = isCopy ? rawItem : ObjectUtils.copy(rawItem);
    const sanitizer = new Sanitizer(this.sanitizer);

    item.hasIcon = item.image !== null && item.image && item.image.thumbnails;

    if (this.platformService.isBrowser) {
      maybeFixDates(item);
    }

    if (item.description_short) {
      sanitizer.createSanitizedFields(item, ['description_short']);

      if (this.platformService.isBrowser) {
        item.description_short = item.description_short_sanitized; // zgodność
      }
    }

    this.setupLink(item);

    return item;
  }

  getEndpoint(): string {
    return NewsSingleItemSetter.getEndpointName();
  }

}

// element kolekcji: wystawa
export class ExhibitionsColletionItemSetter extends ApiCollectionItemSetter {

  setup(rawItem: { [key: string]: any }, isCopy: boolean): { [key: string]: any } {
    const item: { [key: string]: any } = isCopy
      ? rawItem
      : ObjectUtils.copy(rawItem);

    item.isCurrent = typeof rawItem.is_actual !== 'undefined' && parseInt(rawItem.is_actual, 10) === 1
      || rawItem.status === 'exhibition_status_in_preparation'
      || rawItem.status === 'exhibition_status_ongoing';

    // daty
    if (this.platformService.isBrowser) {
      maybeFixDates(item);
    }

    item.url = item.link;

    this.setupLink(item);

    return item;
  }

  getEndpoint(item: { [key: string]: any }): string {
    return ExhibitionSingleItemSetter.getEndpointName();
  }

}

// element kolekcji: oferta
export class OfferColletionItemSetter extends ApiCollectionItemSetter {

  protected artistNamePipe: ArtistNamePipe;
  protected artistDatesPipe: ArtistDatesPipe;

  constructor(
    urlService: UrlService,
    globalService: GlobalService,
    platformService: UniversalPlatformService,
    sanitizer: DomSanitizer,
    artistNamePipe: ArtistNamePipe,
    artistDatesPipe: ArtistDatesPipe,
  ) {
    super(
      urlService,
      globalService,
      platformService,
      sanitizer,
    );

    this.artistNamePipe = artistNamePipe;
    this.artistDatesPipe = artistDatesPipe;
  }

  setup(rawItem: { [key: string]: any }, isCopy: boolean): { [key: string]: any } {
    const item: { [key: string]: any } = isCopy ? rawItem : ObjectUtils.copy(rawItem);
    const sanitizer = new Sanitizer(this.sanitizer);
    const allowedLangs = this.urlService.allowedLangs;

    // obrazy
    item.hasImages = typeof item.images !== 'undefined' && item.images && (item.images || []).length;

    // podpis
    if (item.signature) {
      sanitizer.createSanitizedFields(item, ['signature']);
    }

    // nazwy artystów / linki
    const displayArtistDates = this.globalService.settings.__loaded
      && this.globalService.settings.offer
      && this.globalService.settings.offer.display_artist_dates;

    item.artistsNames = {};

    let li = 0;

    for (; li < (allowedLangs || []).length; ++li) {
      const langCode = allowedLangs[li].code;
      const lang = allowedLangs[li].lang;

      item.artistsNames[langCode] = [];

      if (item.artists && (item.artists || []).length) {
        for (let ai = 0; ai < (item.artists || []).length; ++ai) {
          const artist = item.artists[ai];
          let name = this.artistNamePipe.transform(artist, langCode, 'asHtml');

          if (displayArtistDates) {
            name += ' ' + this.artistDatesPipe.transform(artist, lang, true, false);
          }

          item.artistsNames[langCode].push(name);
        }
      }
    }

    this.setupLink(item);

    return item;
  }

  getEndpoint(item: { [key: string]: any }): string {
    return OfferSingleItemSetter.getEndpointName();
  }

}

// element kolekcji: produkt aukcyjny
export class AuctionProductsColletionItemSetter extends OfferColletionItemSetter {

  setup(rawItem: { [key: string]: any }, isCopy: boolean): { [key: string]: any } {
    const item = super.setup(rawItem, isCopy);
    return item;
  }

  getEndpoint(item: { [key: string]: any }): string {
    return AuctionSingleProductSetter.getEndpointName().replace(':auctionId', item.auction_item.auction_id);
  }

}

/**
 * --- pojedynczy element z API ---
 */
export abstract class ApiSingleItemSetter {

  protected static endPointName = '';
  protected urlService: UrlService;
  protected platformService: UniversalPlatformService;
  protected sanitizer: DomSanitizer;
  protected sugarService: SugarService;
  protected abstract endpoint: string;
  protected abstract type: string;

  constructor(
    urlService: UrlService,
    platformService: UniversalPlatformService,
    sanitizer: DomSanitizer,
    sugarService: SugarService,
  ) {
    this.urlService = urlService;
    this.platformService = platformService;
    this.sanitizer = sanitizer;
    this.sugarService = sugarService;
  }

  setup(rawItem: { [key: string]: any }, sanitizedProps?: string[]): { [key: string]: any } {
    const item: { [key: string]: any } = ObjectUtils.extend(true, {}, rawItem);

    item.id = parseInt(item.id, 10);

    // atrybut alt
    const iLen = (item.images || []).length;

    if (iLen > 0) {
      let index = 0;

      for (; index < iLen; ++index) {
        item.images[index].alt = item.name[this.urlService.langCode];
      }
    }

    // poprzedni / następny
    const prevNext: PrevNextItem = {
      prev: {
        id: 0,
        slug: '',
        link: '',
        routerLink: [],
        url: '',
        query: null,
        parent: null,
      },
      next: {
        id: 0,
        slug: '',
        link: '',
        routerLink: [],
        url: '',
        query: null,
        parent: null,
      },
    };

    if (typeof item._links !== 'undefined') {
      const links = {
        prev: item._links.previous,
        next: item._links.next,
      };

      let prop: 'prev' | 'next';
      let url: string;
      let id: number;

      for (prop in links) {
        if (!links.hasOwnProperty(prop)) {
          continue;
        }

        if (typeof item.parent === 'object') {
          prevNext[prop].parent = item.parent;
        }

        url = decodeURIComponent(links[prop].href);
        id = UrlUtils.extractIdFromEndpointUrl(url, this.endpoint);

        if (item.id !== id && item.id > 0) {
          prevNext[prop].url = url;
          prevNext[prop].id = id;

          prevNext[prop].query = UrlUtils.extractUrlParams(url);

          this.setPrevNextLink(prevNext[prop], prop);
        }
      }
    }

    // właściwe ustawienia
    item.prev = prevNext.prev;
    item.next = prevNext.next;

    // sanityzacja
    if (sanitizedProps && Array.isArray(sanitizedProps)) {
      this.createSanitizedFields(item, sanitizedProps);
    }

    return item;
  }

  createSanitizedFields(theItem: { [key: string]: any }, sanitizedProps: string[]): void {
    const sanitizer = new Sanitizer(this.sanitizer);
    sanitizer.createSanitizedFields(theItem, sanitizedProps);
  }

  protected setPrevNextLink(targetObject: PrevNextContent, direction: 'prev' | 'next'): void {
    const httpParams = targetObject.query.hasParams
      ? targetObject.query.httpParams.toString()
      : '';

    this.sugarService.getItem(
      this.endpoint,
      targetObject.id,
      httpParams,
    ).subscribe((item) => {
      targetObject.slug = typeof item.slug === 'undefined'
        ? {}
        : item.slug;

      targetObject.link = this.getPrevNextItemLink(targetObject, direction, false);
      targetObject.routerLink = this.getPrevNextItemLink(targetObject, direction, true);

      this.standardizePrevNext(targetObject);
    });
  }

  protected transformSearchParam(item: { [key: string]: any }): void {
    if (typeof item.query.params['filter[value]'] !== 'undefined') {
      item.query.params.search = item.query.params['filter[value]'];
      delete item.query.params['filter[value]'];
    }
  }

  protected makeBannerFirst(item: { [key: string]: any }): void {
    // dodanie banneru na początku
    if (typeof item.banner === 'object' && item.banner !== null && item.banner.thumbnails) {
      const iLen = (item.images || []).length;

      let foundBanner = false;
      let index = 0;
      let banner: any;

      // jeśli jest banner to przesuń go na początek tablicy images;
      for (; index < iLen; ++index) {
        if (item.images[index].type === 'banner') {
          banner = item.images[index];
          banner.alt = item.name[this.urlService.langCode]; // alt

          item.images.splice(index, 1);
          item.images.unshift(banner);
          foundBanner = true;
          break;
        }
      }

      if (!foundBanner) {
        item.images.unshift(item.banner);
      }
    }
  }

  protected setBanner(item: { [key: string]: any }): void {
    const sanitizedBanner: SanitizedBanner = {
      type: 'empty',
      thumbnails: null,
      content: null,
    };

    let bannerType = typeof item.banner;

    if (bannerType === 'undefined' || item.banner === null) {
      bannerType = null;
    }

    if (bannerType === 'string') {

      sanitizedBanner.type = 'html';
      sanitizedBanner.content = (item.banner || []).length
        ? this.sanitizer.bypassSecurityTrustHtml(Utils.htmlEntityDecode(item.banner))
        : '';

    } else if (bannerType !== null) {

      // wideo nie ma określonego typu
      if (typeof item.banner.type === 'undefined') {
        sanitizedBanner.type = 'embed';
        sanitizedBanner.content = {};

        const hasUrl = typeof item.banner.url !== 'undefined';
        let lang, code;

        for (lang in this.urlService.allowedLangs) {
          if (!this.urlService.allowedLangs.hasOwnProperty(lang)) {
            continue;
          }

          code = this.urlService.allowedLangs[lang].code;
          sanitizedBanner.content[code] = hasUrl && typeof item.banner.url[code] !== 'undefined'
            ? item.banner.url[code]
            : '';
        }
      } else {
        sanitizedBanner.type = 'image';

        sanitizedBanner.thumbnails = <BannerThumbnails>ObjectUtils.extend(true, {
          detail: '',
          slideshow: '',
          miniature: '',
          medium: '',
          original: '',
        }, item.banner.thumbnails);
      }

    }

    item.sanitized_banner = sanitizedBanner;
  }

  protected standardizePrevNext(item: { [key: string]: any }): void {
    if (typeof item.query.params.offset === 'undefined') {
      item.query.params.offset = null;
    }

    if (typeof item.query.params['filter[group.type]'] !== 'undefined') {
      delete item.query.params['filter[group.type]'];
    }

    if (typeof item.query.params['filter[archive]'] !== 'undefined') {
      item.query.params.archive = item.query.params['filter[archive]'];
      delete item.query.params['filter[archive]'];
    }

    if (this.urlService.queryParams.group) {
      item.query.params.group = this.urlService.queryParams.group;
    }
  }

  protected getPrevNextItemLink(prevNextItem: { [key: string]: any }, direction: 'prev' | 'next', forRouter: boolean): string | string[] {
    let linkParts = [];
    let link = '';

    if ((prevNextItem.url || []).length > 0) {
      if (typeof prevNextItem.parent !== 'undefined' && prevNextItem.parent) {

        linkParts.push(this.urlService.link(this.type));
        linkParts.push(this.urlService.objectLink(prevNextItem.parent, '', true));
        linkParts.push(this.urlService.objectLink(prevNextItem, '', true));

      } else {

        const oLink = this.urlService.link(this.type, prevNextItem);

        if (typeof oLink === 'string') {
          linkParts.push(oLink);
        } else {
          linkParts = linkParts.concat(oLink);
        }

      }

      link = linkParts.join('/');
    }

    return forRouter ? linkParts : link;
  }

}

// pojedynczy element z API: artysta
export class ArtistSingleItemSetter extends ApiSingleItemSetter {

  protected static endPointName = 'artists-single';
  protected endpoint = 'artists';
  protected type = 'artists-single';

  setup(item: { [key: string]: any }, sanitizedProps?: string[]): { [key: string]: any } {
    const theItem = super.setup(item, sanitizedProps);

    this.setBanner(theItem);
    this.makeBannerFirst(theItem);

    if (this.platformService.isBrowser) {
      maybeFixDates(theItem);
    }

    return theItem;
  }

  protected standardizePrevNext(item: { [key: string]: any }): void {
    super.standardizePrevNext(item);
    this.transformSearchParam(item);
  }

}

// pojedynczy element z API: aukcja
export class AuctionSingleItemSetter extends ApiSingleItemSetter {

  protected static endPointName = 'auctions';
  protected endpoint = 'auctions';
  protected type = 'auctions-single';

  static sortListIds(a, b): number {
    if (parseInt(a.auction_item.sort, 10) < parseInt(b.auction_item.sort, 10)) {
      return -1;
    }

    if (parseInt(a.auction_item.sort, 10) > parseInt(b.auction_item.sort, 10)) {
      return 1;
    }

    return 0;
  }

  setup(item: { [key: string]: any }, sanitizedProps?: string[]): { [key: string]: any } {
    const theItem = super.setup(item, sanitizedProps);

    return theItem;
  }

  setupProducts(items: { [key: string]: any }[]): { [key: string]: any }[] {
    const products = [];

    let product: { [key: string]: any };

    for (let i = 0; i < (items || []).length; ++i) {
      product = ObjectUtils.copy(items[i]);
      AuctionSingleProductSetter.mapProductAuctionItem(product);

      products.push(product);
    }

    return products.sort(AuctionSingleItemSetter.sortListIds);
  }

}

// pojedynczy element z API: produkt z aukcji
export class AuctionSingleProductSetter extends ApiSingleItemSetter {

  static toIntAuctionItemProps = [
    'product_id', 'auction_id', 'is_not_sold', 'highlighted', 'is_restore_in_offer',
    'is_droit_de_suite', 'is_conditional_sale', 'is_guarantee_price',
  ];
  static toBoolAuctionItemProps = [
    'is_not_sold', 'highlighted', 'is_restore_in_offer',
    'is_droit_de_suite', 'is_conditional_sale', 'is_guarantee_price',
  ];
  protected endpoint = 'auctions/:auctionId/products';
  protected type = 'auctions-product';

  static mapProductAuctionItem(product: { [key: string]: any }): { [key: string]: any } {
    if (typeof product.auction_item === 'undefined') {
      product.auction_item = {};
    }

    // referencja!
    const auctionItem = product.auction_item;

    let i: number;
    let prop: string;

    for (i = 0; i < (AuctionSingleProductSetter.toIntAuctionItemProps || []).length; ++i) {
      prop = AuctionSingleProductSetter.toIntAuctionItemProps[i];

      if (typeof auctionItem[prop] === 'undefined') {
        auctionItem[prop] = 0;
      } else {
        auctionItem[prop] = parseInt(auctionItem[prop], 10);
      }
    }

    for (i = 0; i < (AuctionSingleProductSetter.toBoolAuctionItemProps || []).length; ++i) {
      prop = AuctionSingleProductSetter.toBoolAuctionItemProps[i];

      if (typeof auctionItem[prop] === 'undefined') {
        auctionItem[prop] = 0;
      } else {
        auctionItem[prop] = !!auctionItem[prop];
      }
    }

    return product;
  }

  static getEndpointName(): string {
    return AuctionSingleProductSetter.endPointName;
  }

  setup(item: { [key: string]: any }, sanitizedProps?: string[]): { [key: string]: any } {
    this.endpoint = this.endpoint.replace(':auctionId', item.auction_item.auction_id);

    // auction_item
    const _item = AuctionSingleProductSetter.mapProductAuctionItem(item);

    // aukcja - rodzic
    if (typeof _item.parentAuction === 'undefined') {
      _item.parentAuction = {};
    }

    // właściwe ustawianie
    const theItem = super.setup(item, sanitizedProps);

    // banner
    this.setBanner(theItem);
    this.makeBannerFirst(theItem);

    return theItem;
  }

  fullySetupItem(item: { [key: string]: any }, sanitizedProps?: string[]): Promise<{ [key: string]: any }> {
    const promise = new Promise((resolve, reject) => {

      const dataSub = this.sugarService.getData().subscribe(data => {
        if (data.collectionId !== 'auction-parent') {
          return;
        }

        item.parent = ObjectUtils.copy(data.data);

        const theItem = this.setup(item, sanitizedProps);

        this.setBanner(theItem);
        this.makeBannerFirst(theItem);
        theItem.parentAuction = theItem.parent;
        delete theItem.parent;

        // daty
        if (this.platformService.isBrowser) {
          maybeFixDates(theItem.parentAuction);
        }

        if (typeof dataSub !== 'undefined') {
          dataSub.unsubscribe();
        }

        resolve(theItem);
      });

    });

    // aukcja nadrzędna
    this.sugarService.setCollection('auction-parent', {
      sugarNode: 'auctions/' + item.auction_item.auction_id,
      expand: 'images',
      parameters: {},
    });

    this.sugarService.refreshData('auction-parent');

    return promise;
  }

  setupRecommended(items: { [key: string]: any }[]): { [key: string]: any }[] {
    const recommended = [];
    let item: { [key: string]: any };

    for (let i = 0; i < (items || []).length; ++i) {
      item = ObjectUtils.copy(items[i]);

      recommended.push(item);
    }

    return recommended.sort(AuctionSingleItemSetter.sortListIds);
  }

  protected standardizePrevNext(item: { [key: string]: any }): void {
    super.standardizePrevNext(item);
    this.transformSearchParam(item);
  }

}

// pojedynczy element z API: news
export class NewsSingleItemSetter extends ApiSingleItemSetter {

  protected static endPointName = 'events';
  protected endpoint = 'events';
  protected type = 'news';

  static getEndpointName(): string {
    return NewsSingleItemSetter.endPointName;
  }

  setup(item: { [key: string]: any }, sanitizedProps?: string[]): { [key: string]: any } {
    const theItem = super.setup(item, sanitizedProps);

    if (this.platformService.isBrowser) {
      maybeFixDates(theItem);
    }

    this.setBanner(theItem);

    return theItem;
  }

  protected standardizePrevNext(item: { [key: string]: any }): void {
    super.standardizePrevNext(item);
    this.transformSearchParam(item);
  }

}

// pojedynczy element z API: wystawa
export class ExhibitionSingleItemSetter extends ApiSingleItemSetter {

  protected static endpointName = '';
  protected endpoint = 'exhibitions';
  protected type = 'exhibitions';

  static getEndpointName(): string {
    return ExhibitionSingleItemSetter.endPointName;
  }

  setup(item: { [key: string]: any }, sanitizedProps?: string[]): { [key: string]: any } {
    const theItem = super.setup(item, sanitizedProps);

    if (this.platformService.isBrowser) {
      maybeFixDates(theItem);
    }

    this.setBanner(theItem);
    this.makeBannerFirst(theItem);

    theItem.isCurrent = parseInt(theItem.active, 10) === 1
      || theItem.status === 'exhibition_status_in_preparation'
      || theItem.status === 'exhibition_status_ongoing';

    return theItem;
  }

  setupEvents(rawEvents: { [key: string]: any }[]): { [key: string]: any }[] {
    const events: { [key: string]: any }[] = [];
    let ev: { [key: string]: any };

    for (let e = 0; e < (rawEvents || []).length; ++e) {
      ev = ObjectUtils.copy(rawEvents[e]);

      if (this.platformService.isBrowser) {
        maybeFixDates(ev);
      }

      events.push(ev);
    }

    return events;
  }

  setupWorks(rawWorks: { [key: string]: any }[]): { [key: string]: any }[] {
    const works: { [key: string]: any }[] = [];
    let work: { [key: string]: any };

    for (let w = 0; w < (rawWorks || []).length; ++w) {
      work = ObjectUtils.copy(rawWorks[w]);

      this.setupExhibitionItem(work);

      if (this.platformService.isBrowser) {
        maybeFixDates(work);
      }

      works.push(work);
    }

    works.sort(this.sortWorksCb);

    // ponowne indeksowanie
    let index = 0;
    works.map((item) => {
      item.sortKey = ++index;
    });

    return works;
  }

  protected standardizePrevNext(item: { [key: string]: any }): void {
    super.standardizePrevNext(item);
    this.transformSearchParam(item);
  }

  private setupExhibitionItem(work: { [key: string]: any }): void {
    if (!work.exhibition_item) {
      work.exhibition_item = {};
    }

    work.product_id = typeof work.product_id === 'undefined' ? parseInt(work.id, 10) : parseInt(work.product_id, 10);
    work.sort = typeof work.sort === 'undefined' ? -1 : parseInt(work.sort, 10);
  }

  private sortWorksCb(itemA: { [key: string]: any }, itemB: { [key: string]: any }): number {
    if (itemA.exhibition_item.sort < 0 && itemB.exhibition_item.sort < 0) {
      return 0;
    } else if (itemA.exhibition_item.sort < 0) {
      return -1;
    } else if (itemB.exhibition_item.sort < 0) {
      return 1;
    }

    if (itemA.exhibition_item.sort < itemB.exhibition_item.sort) {
      return -1;
    }

    if (itemA.exhibition_item.sort > itemB.exhibition_item.sort) {
      return 1;
    }

    return 0;
  }

}

// pojedynczy element z API: oferta
export class OfferSingleItemSetter extends ApiSingleItemSetter {

  protected static endPointName = 'products';
  protected endpoint = 'products';
  protected type = 'products-single';

  static getEndpointName(): string {
    return OfferSingleItemSetter.endPointName;
  }

  setup(item: { [key: string]: any }, sanitizedProps?: string[]): { [key: string]: any } {
    const theItem = super.setup(item, sanitizedProps);

    this.setBanner(theItem);
    this.makeBannerFirst(theItem);

    theItem.isReserved = item.availability === 'object_availabilty_reservated';
    theItem.isAvailable = item.availability === 'object_availabilty_available';
    // theItem.isAvailable = item.availability !== 'object_availabilty_sold' && item.availability !== 'object_availabilty_reservated';
    theItem.isSold = item.availability === 'object_availabilty_sold';

    return theItem;
  }

  protected standardizePrevNext(item: { [key: string]: any }): void {
    super.standardizePrevNext(item);
    this.transformSearchParam(item);
  }

}

/**
 * -- kolekcje ---
 */
export abstract class ApiCollectionSetter {

  setter: ApiCollectionItemSetter;

  protected pipes: {
    replacePlaceholder: ReplacePlaceholderPipe,
    translate: TranslatePipe,
    artistNames: ArtistNamePipe,
    artistDates: ArtistDatesPipe,
  };

  constructor(
    protected urlService: UrlService,
    protected sugarService: SugarService,
    protected platformService: UniversalPlatformService,
    protected globalService: GlobalService,
    protected sanitizer: DomSanitizer,
  ) {
    const artistNamePipe = new ArtistNamePipe();

    const replacePlaceholderPipe = new ReplacePlaceholderPipe(
      this.urlService,
      this.globalService,
    );

    const translatePipe = new TranslatePipe(
      this.globalService,
      this.urlService,
      replacePlaceholderPipe,
    );

    const artistDatesPipe = new ArtistDatesPipe(translatePipe, this.sanitizer, this.globalService);

    this.pipes = {
      replacePlaceholder: replacePlaceholderPipe,
      translate: translatePipe,
      artistNames: artistNamePipe,
      artistDates: artistDatesPipe,
    };

    this.setSetter();
  }

  setup(items: { [key: string]: any }[], collectionName: string): { [key: string]: any }[] {
    const collection = [];

    // const copy = ObjectUtils.copy(items);
    const copy = items.slice(0);

    const len = (copy || []).length;
    let i = 0;
    let item;

    for (; i < len; ++i) {
      item = this.setter.setup(copy[i], true);
      collection.push(item);
    }

    return collection;
  }

  abstract setSetter(): void;

}

// kolekcje: oferta
export class OfferColletionSetter extends ApiCollectionSetter {

  setSetter(): void {
    this.setter = new OfferColletionItemSetter(
      this.urlService,
      this.globalService,
      this.platformService,
      this.sanitizer,
      this.pipes.artistNames,
      this.pipes.artistDates,
    );
  }

}

// kolekcje: produkty aukcyjne
export class AuctionProductsColletionSetter extends OfferColletionSetter {

  setSetter(): void {
    this.setter = new AuctionProductsColletionItemSetter(
      this.urlService,
      this.globalService,
      this.platformService,
      this.sanitizer,
      this.pipes.artistNames,
      this.pipes.artistDates,
    );
  }

}

// kolekcje: newsy
export class NewsColletionSetter extends ApiCollectionSetter {

  setSetter(): void {
    this.setter = new NewsColletionItemSetter(
      this.urlService,
      this.globalService,
      this.platformService,
      this.sanitizer,
      this.pipes.artistNames,
      this.pipes.artistDates,
    );
  }

}

// kolekcje: wystawy
export class ExhibitionsColletionSetter extends ApiCollectionSetter {

  setSetter(): void {
    this.setter = new ExhibitionsColletionItemSetter(
      this.urlService,
      this.globalService,
      this.platformService,
      this.sanitizer,
    );
  }

  setupArchive(items: { [key: string]: any }[], collectionName: string): { [key: string]: any }[] {
    return this.setup(items, collectionName);
  }

}
