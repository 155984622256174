import { OnInit, SecurityContext } from '@angular/core';
import { currenciesArray, CurrencyService } from '../model/currency.service';
import { thousandSeparator } from '../helpers/helpers';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalService } from '../model/global.service';
import { UrlService } from '../model/url.service';
var PriceExchangeComponent = /** @class */ (function () {
    function PriceExchangeComponent(currencyService, domSanitizer, globalService, urlService) {
        var _this = this;
        this.currencyService = currencyService;
        this.domSanitizer = domSanitizer;
        this.globalService = globalService;
        this.urlService = urlService;
        this.type = 'work';
        this.item = null;
        this.paddingLeft = '0';
        this.afterExchange = null;
        this.selectedCurrency = 'PLN';
        this._fromCurrency = 'PLN';
        currencyService.onCurrencyChange.subscribe(function (value) { return _this.countValue(value); });
        if (urlService.lang === 'en') {
            currencyService.changeTargetCurrency('EUR');
        }
        urlService.langChange.subscribe(function (lang) {
            if (lang.langTo === 'en') {
                currencyService.changeTargetCurrency('EUR');
            }
        });
    }
    Object.defineProperty(PriceExchangeComponent.prototype, "price", {
        get: function () {
            return Number(this._price);
        },
        set: function (price) {
            var priceString = this.domSanitizer.sanitize(SecurityContext.HTML, price);
            var priceArr = priceString.split('&nbsp;');
            if (Number.isInteger(Number(priceArr[(priceArr || []).length - 1]))) {
                this._price = Number(priceArr.join(''));
            }
            else {
                this.fromCurrency = priceArr.pop();
                this._price = Number(priceArr.join(''));
            }
            if (this.type) {
                this.countValue(this.selectedCurrency);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PriceExchangeComponent.prototype, "fromCurrency", {
        get: function () {
            return this._fromCurrency;
        },
        set: function (currency) {
            if (!(currency in currenciesArray)) {
                currency = 'PLN';
            }
            currency = currency === 'zł' ? 'PLN' : currency;
            this._fromCurrency = currency;
        },
        enumerable: true,
        configurable: true
    });
    PriceExchangeComponent.prototype.ngOnInit = function () {
    };
    PriceExchangeComponent.prototype.countValue = function (currency) {
        this.selectedCurrency = currency;
        if (this.selectedCurrency === this.fromCurrency) {
            this.afterExchange = null;
            return;
        }
        switch (this.type) {
            case 'auction':
                this.countEstimation(currency);
                break;
            case 'work':
                this.countPrice(currency);
                break;
            default:
                return;
        }
    };
    PriceExchangeComponent.prototype.countPrice = function (currency) {
        var _this = this;
        this.currencyService.getCurrencyExchangeValues(this.fromCurrency).subscribe(function (value) {
            var val = Math.ceil((Number(_this.price) * value.rates[currency]) / 10) * 10;
            _this.afterExchange = thousandSeparator(val) + " " + _this.selectedCurrency;
        });
    };
    PriceExchangeComponent.prototype.countEstimation = function (currency) {
        var _this = this;
        this.currencyService.getCurrencyExchangeValues(this.fromCurrency).subscribe(function (value) {
            var from = (_this.item.auction_item.estimation_from && (_this.item.auction_item.estimation_from || []).length)
                ? _this.item.auction_item.estimation_from : '';
            var to = (_this.item.auction_item.estimation_to && (_this.item.auction_item.estimation_to || []).length)
                ? _this.item.auction_item.estimation_to : '';
            from = Math.ceil((from * value.rates[currency]) / 10) * 10;
            from = "" + thousandSeparator(from);
            to = Math.ceil((to * value.rates[currency]) / 10) * 10;
            to = thousandSeparator(to) + " " + _this.selectedCurrency;
            _this.afterExchange = from + " - " + to;
        });
    };
    return PriceExchangeComponent;
}());
export { PriceExchangeComponent };
