/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./currencies-select.component";
import * as i3 from "../../model/currency.service";
import * as i4 from "@angular/router";
import * as i5 from "../../model/global.service";
var styles_CurrenciesSelectComponent = ["select[_ngcontent-%COMP%] {\n      border: none;\n      cursor: pointer;\n      height: 20px;\n      width: 20px;\n      -webkit-appearance: none;\n      -moz-appearance: none;\n      \n      background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIPnI+py+0/hJzz0IruwjsVADs=) no-repeat 40% 30%;\n      text-indent: -100vw;\n      outline: none;\n    }\n\n    select[_ngcontent-%COMP%]   option[_ngcontent-%COMP%] {\n      color: #f4a701;\n      background: #000;\n    }\n\n    label[_ngcontent-%COMP%] {\n      margin: 0;\n    }"];
var RenderType_CurrenciesSelectComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_CurrenciesSelectComponent, data: {} });
export { RenderType_CurrenciesSelectComponent as RenderType_CurrenciesSelectComponent };
function View_CurrenciesSelectComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "option", [], [[8, "value", 0]], null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_1); }); }
function View_CurrenciesSelectComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "label", [["for", "select"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "select", [["id", "select"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.changeTargetCurrency($event, $event.target.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CurrenciesSelectComponent_2)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currencies; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_CurrenciesSelectComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CurrenciesSelectComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.globalService.settings.offer.show_prices_conversion; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CurrenciesSelectComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-currencies-select", [], null, null, null, View_CurrenciesSelectComponent_0, RenderType_CurrenciesSelectComponent)), i0.ɵdid(1, 114688, null, 0, i2.CurrenciesSelectComponent, [i3.CurrencyService, i4.Router, i4.ActivatedRoute, i5.GlobalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CurrenciesSelectComponentNgFactory = i0.ɵccf("app-currencies-select", i2.CurrenciesSelectComponent, View_CurrenciesSelectComponent_Host_0, { price: "price" }, {}, []);
export { CurrenciesSelectComponentNgFactory as CurrenciesSelectComponentNgFactory };
