<div class="recommendations-block {{ mode }}-carousel-block">

    <section
        *ngIf="items && items?.length > 0"
        class="home-recommendations recommendations-section recommended-items-section recommended-block container-fluid">

        <div *ngIf="block.title" class="recommendations-section-title">
            <div class="recommendations-title styled-header">{{ block.title }}</div>
        </div>

        <div class="owl-carousel-outer">

            <owl-carousel #owl *ngIf="enableOwl"
                          [carouselClasses]="['owl-carousel', 'recommendations-carousel', 'owl-theme']"
                          [options]="options">

                <div *ngFor="let item of items; let iIndex = index" class="owl-slide">
                    <div class="grid-item-inner">
                        <article class="owl-slide-article">
                            <a (click)="onClickItem(iIndex+1)" [queryParams]="{
                                    offset:(iIndex+1)
                                }" [routerLink]="urlService.link('products', item)"
                               class="owl-slide-link grid-item-link">

                                <div [ngClass]="{'no-zoom': !zoomImages}" class="grid-item-figure offer">

                                    <img *ngIf="item.hasImages" appLazyLoad class="img-fluid"
                                         src="{{ item.images[0]?.thumbnails?.medium.url }}"/>

                                    <img *ngIf="! item.hasImages" appLazyLoad class="img-fluid"
                                         src="assets/images/placeholder-600x450.jpg"/>

                                    <!-- <span *ngIf="item.image?.length && item.images[0].thumbnails" class="grid-item-figure" [style.background-image]="'url('+item.images[0]?.thumbnails?.medium.url+')'"></span>

                                    <span *ngIf="!item.image?.length || !item.images[0].thumbnails" class="grid-item-figure" style="background-image:url('assets/images/placeholder-600x450.jpg')"></span> -->

                                </div>

                                <div *ngIf="item.artistsNames[urlService.langCode]?.length"
                                     class="grid-item-title artist-name">

                                    <ng-container
                                        *ngFor="let artistName of item.artistsNames[urlService.langCode]; let aIndex = index">
                                        <span [innerHTML]="artistName"></span>
                                        <ng-container
                                            *ngIf="aIndex < (item.artistsNames[urlService.langCode]?.length - 1)">
                                            ,<br/></ng-container>
                                    </ng-container>

                                </div>

                                <p class="grid-item-text offer-title">
                                    <span
                                        [ngClass]="{'with-underline':activeTextElementsOnHoverEnabled, 'hoverable-element':true}"
                                        class="title">
                                        {{ item.name[urlService.langCode] }}</span><span
                                    *ngIf="item.year || item.year_to || item.year_text[urlService.langCode]"
                                    class="work-date">,
                                    {{ item | workDates:urlService.langCode }}</span>
                                    <br/>
                                    <span
                                        *ngIf="item.description_short[urlService.langCode]">{{ item.description_short[urlService.langCode] }}
                                        <br/></span>

                                </p>
                                <p [innerHTML]="item | workDimensions:urlService.lang:true"
                                   class="work-dimensions"></p>

                                <p *ngIf="mode !== 'products_auction_featured' && globalService.settings.offer.show_prices" class="masonry-grid-item-info">
                                    {{'offer.price' | translate:urlService.lang}}:
                                    <span>
                                            <span
                                                [innerHTML]="item | workPrice"></span>
                                                <app-price-exchange
                                                    [price]="item | workPrice"></app-price-exchange>
                                        </span>
                                    <app-currencies-select></app-currencies-select>
                                </p>

                            </a>

                        </article>
                    </div>
                </div>

            </owl-carousel>

        </div>

        <div class="owl-nav wrapper-container">
            <div #nav class="recommendations-nav"></div>
        </div>

    </section>

</div>
