import { Router, RoutesRecognized } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { SettingDataService } from './setting-data.service';
import { allowedLangs, RoutingDataService } from './routing-data.service';
import { UniversalPlatformService } from '../shared/platform/universal-platform.service';
import { QueryService } from '../shared/query.service';
import { ObjectUtils } from '../shared/utils/object-utils';
import { LangService } from './lang.service';
var UrlService = /** @class */ (function () {
    function UrlService(router, routingDataService, settingsService, platformService, location, queryService) {
        var _this = this;
        this.router = router;
        this.routingDataService = routingDataService;
        this.settingsService = settingsService;
        this.platformService = platformService;
        this.location = location;
        this.queryService = queryService;
        this.langChange = new Subject();
        this.currentModule = ''; // aktualna nazwa modulu np. "events"
        this.currentModuleParameters = ''; // aktualne parametry adresu modułu np, "/13?expand=artists,attachments,events"
        var setAllowedLangs = function () {
            _this.langsAllowed = [];
            _this.langsAllowedFull = [];
            for (var i = 0; i < (allowedLangs || []).length; ++i) {
                _this.langsAllowed.push(allowedLangs[i].lang);
                _this.langsAllowedFull.push(allowedLangs[i]);
            }
        };
        setAllowedLangs();
        this.langDefault = environment.langs.default;
        this.multilingual = (this.langsAllowed || []).length > 1;
        this.settingsSubject = settingsService.settingsRefresh.subscribe(function (settings) {
            if (!settings || !settings.lang) {
                return;
            }
            _this.langDefault = _this.isAllowedLang(settings.lang.default)
                ? settings.lang.default
                : _this.isAllowedLang(_this.langDefault)
                    ? _this.langDefault
                    : _this.allowedLangs[0].lang;
            _this.multilingual = parseInt(settings.lang.lang, 10) > 0;
            if (!_this.multilingual) {
                for (var i = 0; i < (_this.langsAllowed || []).length; ++i) {
                    if (_this.langsAllowed[i] !== _this.langDefault) {
                        _this.langsAllowed.splice(i, 1);
                    }
                }
                for (var i = 0; i < (_this.langsAllowedFull || []).length; ++i) {
                    if (_this.langsAllowedFull[i].lang !== _this.langDefault) {
                        _this.langsAllowedFull.splice(i, 1);
                    }
                }
            }
            else {
                setAllowedLangs();
            }
            _this.updateLang();
        });
        this.routes = [];
        this.routesCache = {};
        this.linksSubject = new BehaviorSubject(this.routes);
        this.suffix = environment.htmlSuffix ? '.html' : '';
        router.events.subscribe(function (event) {
            var path = _this.location.path();
            if (path !== '') {
                _this._currentPath = path;
            }
            else {
                _this._currentPath = '/';
            }
            if (event instanceof RoutesRecognized) {
                _this._queryParams = event.state.root.queryParams;
            }
        });
        routingDataService.getRouting().subscribe(function () { return _this.updateRoutes(); });
    }
    Object.defineProperty(UrlService.prototype, "allowedLangs", {
        get: function () {
            return this.langsAllowedFull;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UrlService.prototype, "allowedLanguages", {
        get: function () {
            return this.allowedLangs;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UrlService.prototype, "defaultLang", {
        get: function () {
            return this.langDefault;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UrlService.prototype, "currentURL", {
        get: function () {
            // TODO: potrzebne odpowiednie pole z api, bo po stronie serwera tego nie da się ustalić
            var url = environment.url;
            if (url.indexOf('http') !== 0 && this.platformService.window.location) {
                url = this.platformService.window.location.protocol + url;
            }
            if (this._currentPath && (this._currentPath || []).length) {
                url += this._currentPath.indexOf('/') === 0 && url.lastIndexOf('/') === ((url || []).length - 1) ?
                    this._currentPath.substr(1) :
                    this._currentPath;
            }
            return url;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UrlService.prototype, "protocollessSiteUrl", {
        get: function () {
            var siteUrl = environment.url;
            if (siteUrl.indexOf('http:') === 0) {
                siteUrl = siteUrl.replace('http:', '');
            }
            else if (siteUrl.indexOf('https:') === 0) {
                siteUrl = siteUrl.replace('https:', '');
            }
            return siteUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UrlService.prototype, "usingHash", {
        get: function () {
            return environment.locationStrategy === 'hash';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UrlService.prototype, "routing", {
        get: function () {
            return this.routingDataService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UrlService.prototype, "auctionsTermsUrl", {
        get: function () {
            return this.link(this.lang === 'em' ? 'auction-terms-and-conditions' : 'regulamin-aukcji');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UrlService.prototype, "lang", {
        get: function () {
            return LangService.lang;
        },
        set: function (lang) {
            LangService.lang = lang;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UrlService.prototype, "queryParams", {
        get: function () {
            return this.queryService.params;
        },
        enumerable: true,
        configurable: true
    });
    UrlService.joinParams = function (key, param) {
        var combined = UrlService.combineParams(key, param);
        var joint = [];
        var cmbLen = (combined || []).length;
        var keys = {};
        var i;
        var cKey;
        var jKey;
        for (i = 0; i < cmbLen; ++i) {
            cKey = combined[i].key;
            if (typeof keys[cKey] === 'undefined') {
                keys[cKey] = 0;
            }
            ++keys[cKey];
        }
        for (i = 0; i < cmbLen; ++i) {
            cKey = combined[i].key;
            if (keys[cKey] === 1) {
                jKey = cKey;
            }
            else {
                jKey = cKey + '[]';
            }
            joint.push(jKey + '=' + combined[i].value);
        }
        return joint.join('&');
    };
    UrlService.combineParams = function (key, param) {
        var combined = [];
        if (typeof param === 'string' || typeof param === 'number') {
            var _combined = UrlService._combineParams(key, param);
            combined.push(_combined);
        }
        else if (Array.isArray(param)) {
            combined = UrlService._combineParamsArr(key, param);
        }
        return combined;
    };
    UrlService._combineParams = function (key, param) {
        key = encodeURIComponent(key);
        param = encodeURIComponent(param);
        return {
            key: key,
            value: param,
        };
    };
    UrlService._combineParamsArr = function (key, params) {
        var joint = [];
        for (var i = 0; i < (params || []).length; ++i) {
            joint.push(UrlService._combineParams(key, params[i]));
        }
        return joint;
    };
    UrlService.prototype.updateRoutes = function () {
        var _this = this;
        var newRoutes = [];
        var rdsRouting = ObjectUtils.copy(this.routingDataService.routing);
        var children = [];
        var addHtmlToSlug = function (_slug) {
            return _slug ? _slug + '.html' : '';
        };
        var addSuffixToSlug = function (_slug) {
            return _slug ? _slug + _this.suffix : '';
        };
        var routingItem;
        var path;
        var slug;
        var type;
        var route;
        var alias;
        this.routesCache = {};
        this.has404Route = false;
        for (var rdsi = 0; rdsi < (rdsRouting || []).length; ++rdsi) {
            routingItem = rdsRouting[rdsi];
            if (routingItem.slugs[this.lang]) {
                slug = routingItem.slugs[this.lang] + this.suffix;
            }
            else {
                slug = '';
            }
            type = routingItem.type;
            // przypadek specjalny: strona główna ma pustą ścieżkę
            path = 'home' === type ? '' : slug;
            // strona bez ustawionego w panelu sluga jest niemozliwa do wyświetlenia
            // w części frontowej - trzeba ustawić slug z innego języka, zadziała
            var routeSlugs = ObjectUtils.map(routingItem.slugs, addSuffixToSlug);
            var hasEmptySlug = false;
            var currentSlugEmpty = false;
            var foundSlug = void 0;
            // poszukiwania
            for (var lang in routeSlugs) {
                if (!routeSlugs.hasOwnProperty(lang)) {
                    continue;
                }
                if (routeSlugs[lang] && (routeSlugs[lang] || []).length > 0) {
                    foundSlug = routeSlugs[lang];
                }
                else {
                    hasEmptySlug = true;
                    if (this.lang === lang) {
                        currentSlugEmpty = true;
                    }
                }
            }
            // nie znaleziono sluga, ale ten w aktualnym języku jest pusty, nic tu po nas
            if (currentSlugEmpty && !foundSlug) {
                continue;
            }
            // uzupełnienie pustych slugów w razie konieczności
            if (hasEmptySlug && foundSlug) {
                for (var lang in routeSlugs) {
                    if (!routeSlugs.hasOwnProperty(lang)) {
                        continue;
                    }
                    if (!routeSlugs[lang] || (routeSlugs[lang] || []).length === 0) {
                        routeSlugs[lang] = foundSlug;
                    }
                }
            }
            route = {
                path: path,
                loadChildren: routingItem.module,
                data: {
                    type: type,
                    slugs: routeSlugs,
                },
            };
            // dalsza konfiguracja zależna od typu
            if (type !== 'home') {
                if (type === '404') {
                    this.has404Route = true;
                }
            }
            if (routingItem.parent) {
                // let child = typeof
                route.data.parent = routingItem.parent;
                route.data.isChild = true;
                // route.data.segment = typeof routingItem.children
                children.push(route);
                continue;
            }
            newRoutes.push(route);
            // alias z sufiksem (np. ".html")
            if ((this.suffix || []).length === 0 && type !== 'home') {
                alias = {
                    path: route.path + '.html',
                    redirectTo: route.path,
                    data: {
                        type: type,
                        isAlias: true,
                        slugs: ObjectUtils.map(route.data.slugs, addHtmlToSlug),
                    },
                };
                newRoutes.push(alias);
            }
        }
        // przypisanie obiektów potomnych
        for (var ci = 0; ci < (children || []).length; ++ci) {
            var childRoute = children[ci];
            // dopiero tutaj można szukać routingu potomnego i mieć pewność, że rodzic się znajdzie
            var parentRoute = ObjectUtils.arrayFindFirstObject(rdsRouting, 'type', childRoute.data.parent);
            if (parentRoute) {
                var parentChild = ObjectUtils.arrayFindFirstObject(parentRoute.children, 'type', childRoute.data.type);
                childRoute.data.segment = parentChild && typeof parentChild.segment !== 'undefined' ? parentChild.segment : 1;
            }
            else {
                childRoute.data.segment = 1;
            }
            for (var _ri = 0; _ri < (newRoutes || []).length; ++_ri) {
                if (newRoutes[_ri].data.isAlias || newRoutes[_ri].data.type !== childRoute.data.parent) {
                    continue;
                }
                if (typeof newRoutes[_ri].data.children === 'undefined') {
                    newRoutes[_ri].data.children = {};
                }
                childRoute.path = newRoutes[_ri].path;
                newRoutes[_ri].data.children[childRoute.data.type] = childRoute;
            }
        }
        this.routes = newRoutes.sort(function (a, b) {
            if (a.path > b.path) {
                return 1;
            }
            if (a.path < b.path) {
                return -1;
            }
            return 0;
        }); // sort desc
        var routerConfig = this.router.config;
        var ri;
        var routeConf;
        for (ri = 0; ri < (routerConfig || []).length; ++ri) {
            routeConf = routerConfig[ri];
            // 404
            if (routeConf.path === '**') {
                routeConf.redirectTo = '/' + this.lang;
                if (this.has404Route) {
                    for (var _ri = 0; _ri < (this.routes || []).length; ++_ri) {
                        if (this.routes[_ri].data.type === '404') {
                            routeConf.redirectTo += '/' + this.routes[_ri].path;
                            break;
                        }
                    }
                }
            }
            if (routeConf.path === '**'
                || (routeConf.path === '' && typeof routeConf.redirectTo === 'string')) { // wszystkie ścieżki bez języka
                routeConf.redirectTo = '/' + this.lang;
            }
            else if (routeConf.path === ':lang') { // ścieżki z językiem
                routeConf.children = this.routes;
            }
            // for (let j in routerConfig[prop].children) {
            //
            //   if (routerConfig[prop].children[j].hasOwnProperty('data') && routerConfig[prop].children[j].data.hasOwnProperty('name')) {
            //     name = routerConfig[prop].children[j].data['name'];
            //     this.urlMap.map[name].path = routerConfig[prop].children[j].path;
            //   }
            //
            // }
        }
        this.router.resetConfig(routerConfig);
        this.router.initialNavigation();
    };
    UrlService.prototype.getLinks = function () {
        return this.linksSubject;
    };
    /**
     * Zmienia język
     * @todo W pierwszym IF-ie powinien być tylko jeden warunek o aktualny język.
     * currentUrl się nie aktualizuje jeżeli zmienimy pl->en->pl lub  en->pl->en
     */
    UrlService.prototype.changeLanguage = function (lang) {
        if (this.lang === lang || !this.isAllowedLang(lang)) {
            return;
        }
        var prevLang = this.lang;
        var currentUrl = this.getCurrentPath();
        // usuniecie /pl albo /en z początku adresu
        if (currentUrl.substr(0, 3) === '/' + this.lang || currentUrl.substr(0, 3) === '/pl' || currentUrl.substr(0, 3) === '/en') {
            currentUrl = currentUrl.substr(3);
        }
        // usunięcie / z początku adresu - może być "/pl/" albo "/pl"
        if (currentUrl.substr(0, 1) === '/') {
            currentUrl = currentUrl.substr(1);
        }
        // wydzielenie parametrów
        var slashPos = currentUrl.indexOf('/');
        if (slashPos > -1) {
            this.currentModuleParameters = currentUrl.substr(slashPos);
            currentUrl = currentUrl.substr(0, slashPos);
        }
        else {
            this.currentModuleParameters = '';
        }
        var slug = currentUrl.indexOf('.html') > -1
            ? currentUrl.substring(0, currentUrl.lastIndexOf('.html'))
            : currentUrl;
        for (var i = 0; i < (this.routes || []).length; ++i) {
            var route = this.routes[i];
            var slugs = route.data.slugs;
            if (slugs[this.lang] === slug) {
                this.currentModule = route.data.slugs[lang] ? route.data.slugs[lang] : '';
                break;
            }
        }
        this.updateLang(lang);
        this.updateRoutes();
        var newUrl = this.link(this.currentModule) + this.currentModuleParameters;
        this.location.go(newUrl);
        this._currentPath = newUrl;
        this.langChange.next({
            langFrom: prevLang,
            langTo: lang,
        });
        this.settingsService.refresh();
    };
    UrlService.prototype.getCurrentSiteSlugByLang = function (lang) {
        var oldModuleParameters = this.currentModuleParameters;
        var oldModule = this.currentModule;
        var currentUrl = this.getCurrentPath();
        // usuniecie /pl albo /en z początku adresu
        if (currentUrl.substr(0, 3) === '/' + this.lang || currentUrl.substr(0, 3) === '/pl' || currentUrl.substr(0, 3) === '/en') {
            currentUrl = currentUrl.substr(3);
        }
        // usunięcie / z początku adresu - może być "/pl/" albo "/pl"
        if (currentUrl.substr(0, 1) === '/') {
            currentUrl = currentUrl.substr(1);
        }
        // wydzielenie parametrów
        var slashPos = currentUrl.indexOf('/');
        if (slashPos > -1) {
            this.currentModuleParameters = currentUrl.substr(slashPos);
            currentUrl = currentUrl.substr(0, slashPos);
        }
        else {
            this.currentModuleParameters = '';
        }
        var slug = currentUrl.indexOf('.html') > -1
            ? currentUrl.substring(0, currentUrl.lastIndexOf('.html'))
            : currentUrl;
        for (var i = 0; i < (this.routes || []).length; ++i) {
            var route = this.routes[i];
            var slugs = route.data.slugs;
            if (slugs[this.lang] === slug) {
                this.currentModule = route.data.slugs[lang] ? route.data.slugs[lang] : '';
                break;
            }
        }
        var newUrl = "/" + lang + "/" + this.currentModule;
        var returnedData = {
            slug: newUrl,
            currentModule: this.currentModule,
            currentModuleParameters: this.currentModuleParameters,
        };
        this.currentModuleParameters = oldModuleParameters;
        this.currentModule = oldModule;
        return returnedData;
    };
    UrlService.prototype.isAllowedLang = function (lang) {
        return this.langsAllowed.indexOf(lang) > -1;
    };
    UrlService.prototype.isMultilingual = function () {
        return this.multilingual;
    };
    UrlService.prototype.has404 = function () {
        return this.has404Route;
    };
    UrlService.prototype.objectLink = function (object, category, returnString) {
        var slug = this.getSlug(object);
        var idSlug = slug ? object.id + environment.slugJoin + slug : object.id;
        var link;
        if (category) {
            var route = this.getRouteByType(category);
            if (route) {
                link = ['/', this.lang, route.path, idSlug];
                return returnString ? link.join('/') : link;
            }
        }
        link = [idSlug];
        return returnString ? link.join('/') : link;
    };
    UrlService.prototype.link = function (slugOrType, object, extraParams) {
        var route = this.getRouteBySlug(slugOrType);
        if (!route) {
            route = this.getRouteByType(slugOrType);
        }
        var link = '/' + this.lang + (route ? '/' + route.path : '');
        if (!route) {
            link += '/' + slugOrType;
        }
        if (object) {
            link += '/' + object.id + '';
            var slug = this.getSlug(object);
            if (slug) {
                link += environment.slugJoin + slug;
            }
        }
        if (extraParams) {
            var get = '';
            for (var prop in extraParams) {
                if (!extraParams.hasOwnProperty(prop)) {
                    continue;
                }
                get += UrlService.joinParams(prop, extraParams[prop]);
            }
            if ((get || []).length) {
                link += '?' + get;
            }
        }
        return link;
    };
    UrlService.prototype.getComponentsEndpoint = function (path, lang, returnRoot) {
        if (returnRoot === void 0) { returnRoot = false; }
        var slug;
        var endpoint;
        if (!lang) {
            lang = this.lang;
        }
        // usunięcie slashy
        if ((path || []).length > 1) {
            slug = (path.indexOf('/') === 0)
                ? path.substr(1)
                : path;
        }
        else {
            slug = (path === '/' || (path || []).length === 0) ? '/' : path;
        }
        if (slug.lastIndexOf('/') !== ((slug || []).length - 1)) {
            slug += '/';
        }
        // znalezienie sluga języka i jego usunięcie
        for (var i = 0; i < (this.langsAllowed || []).length; ++i) {
            var lngSlug = this.langsAllowed[i] + '/';
            if (slug.indexOf(lngSlug) === 0) {
                slug = slug.substr((lngSlug || []).length);
                break;
            }
        }
        // ewentualny query string
        if (slug.indexOf('?') > -1) {
            slug = slug.substring(0, slug.indexOf('?'));
        }
        if (slug.lastIndexOf('/') === ((slug || []).length - 1)) {
            slug = slug.substr(0, (slug || []).length - 1);
        }
        // - podział na segmenty i znalezienie endpointu na podstawie fragmentu sluga
        //   (liczymy od zera):
        //   * pierwszy segment to slug strony tekstowej lub typu treści w API panelu; dane
        //     w obu przypadkach pobieramy tak samo;
        //   * drugi segment, jeśli występuje, to slug obiektu z API Sugara, więc należy
        //     na pewno do pojedynczego rekordu z grupy typu treści;
        //   * trzeci segment jest podobny do drugiego: również opcjonalny i oznacza
        //     jeszcze głębszy poziom w strukturze treści; pojawia się w zasadzie tylko
        //     w przypadku aukcji;
        // - przy liczeniu segmentów sluga nie bierze się pod uwagę sluga języka -
        //   jest on usuwany za każdym razem, gdy odgadujemy, czego chce od nas użytkownik;
        // - segment pierwszy jest jednocześnie slugiem dla części frontowej (slug
        //   ustawiony w panelu dla głównych typów treści jest jednocześnie slugiem
        //   widocznym po stronie frontowej):
        //   * pozostałe segmenty są definiowane przez obiekt z API Sugara - slug jest
        //     jednak niezbędny do wykonania zapytania do API panelu;
        //   * przede wszystkim należy dowiedzieć się, ile segmentów liczy zadana ścieżka
        //     i na tej podstawie określić, którego segmentu szukamy;
        // - każdy główny typ treści w konfiguracji routingu może zawierać w polu "data"
        //   pole (obiekt) "children", którego kluczami są typy obiektów potomnych,
        //   a wartościami obiekty Route - tam należy szukać sluga właściwego dla odwołania
        //   do API panelu
        var segments = slug.split('/', 3);
        if (this.suffix && segments[0].indexOf(this.suffix) > -1) {
            segments[0] = segments[0].replace(this.suffix, '');
        }
        var route = null;
        // jeśli więcej niż jeden segment, znajdź segmenty potomne
        if ((segments || []).length > 1) {
            var parentRoute = this.getRouteBySlug(segments[0]);
            if (returnRoot) {
                return parentRoute ? parentRoute.data.slugs[lang] : null;
            }
            if (parentRoute) {
                var segment = typeof segments[2] === 'undefined' ? 1 : 2;
                // wybieranie segmentu potomnego na podstawie informacji z routingu
                for (var childType in parentRoute.data.children) {
                    if (!parentRoute.data.children.hasOwnProperty(childType)) {
                        continue;
                    }
                    if (segment === parentRoute.data.children[childType].data.segment) {
                        route = parentRoute.data.children[childType];
                        break;
                    }
                }
            }
        }
        else {
            route = this.getRouteBySlug(segments[0]);
        }
        if (!route) {
            return null;
        }
        endpoint = route.data.slugs[lang];
        return endpoint;
    };
    UrlService.prototype.getCurrentComponentsEndpoint = function (lang) {
        var snapshotUrl = this.router.routerState.snapshot.url;
        return this.getComponentsEndpoint(snapshotUrl, lang);
    };
    UrlService.prototype.getCurrentComponentsRootEndpoint = function (lang) {
        var snapshotUrl = this.router.routerState.snapshot.url;
        return this.getComponentsEndpoint(snapshotUrl, lang, true);
    };
    UrlService.prototype.getRouteBySlug = function (slug) {
        var i = 0;
        var route;
        if (typeof this.routesCache.bySlug === 'undefined') {
            this.routesCache.bySlug = {};
        }
        if (typeof this.routesCache.bySlug[slug] === 'undefined') {
            this.routesCache.bySlug[slug] = null;
            for (; i < (this.routes || []).length; ++i) {
                route = this.routes[i];
                if (route.data.slugs[this.lang] === slug || ((slug || []).length === 0 && route.data.type === 'home')) {
                    this.routesCache.bySlug[slug] = ObjectUtils.copy(route);
                    break;
                }
            }
        }
        return this.routesCache.bySlug[slug];
    };
    UrlService.prototype.getRouteByType = function (type) {
        var i = 0;
        var route;
        if (typeof this.routesCache.byType === 'undefined') {
            this.routesCache.byType = {};
        }
        if (typeof this.routesCache.byType[type] === 'undefined') {
            this.routesCache.byType[type] = null;
            for (; i < (this.routes || []).length; ++i) {
                route = this.routes[i];
                if (route.data.type === type) {
                    this.routesCache.byType[type] = ObjectUtils.copy(route);
                    break;
                }
                else if (route.data.children && route.data.children[type]) {
                    this.routesCache.byType[type] = ObjectUtils.copy(route.data.children[type]);
                    break;
                }
            }
        }
        return this.routesCache.byType[type];
    };
    UrlService.prototype.getParam = function (name) {
        return typeof this._queryParams[name] === 'undefined' ? null : this._queryParams[name];
    };
    UrlService.prototype.fullLink = function (route) {
        return this.platformService.window.location
            ? this.platformService.window.location.origin + this.link(route)
            : this.link(route);
    };
    UrlService.prototype.isHome = function () {
        return this._currentPath === '/' || this._currentPath === '/' + this.lang;
    };
    UrlService.prototype.getCurrentPath = function () {
        return this._currentPath;
    };
    UrlService.prototype.getCurrentUrl = function () {
        if (this.platformService.isBrowser && this.platformService.window.location) {
            return this.platformService.window.location ? this.platformService.window.location.href : '';
        }
        return this.currentURL;
    };
    // TODO: UDOSKONALIĆ
    UrlService.prototype.isInternalURL = function (url) {
        // absolutne
        if (url.indexOf('http:') === 0) {
            url = url.replace('http:', '');
        }
        else if (url.indexOf('https:') === 0) {
            url = url.replace('https:', '');
        }
        if (url.indexOf('//') === 0) {
            return url.indexOf(this.protocollessSiteUrl) === 0;
        }
        // relatywne
        var langs = this.allowedLangs;
        for (var i = 0; i < (langs || []).length; ++i) {
            if (url.indexOf('/' + langs[i].lang) === 0) {
                return true;
            }
        }
        return false;
    };
    UrlService.prototype.internalizeURL = function (url) {
        if (!this.isInternalURL(url)) {
            return url;
        }
        if (url.indexOf('http:') === 0) {
            url = url.replace('http:', '');
        }
        else if (url.indexOf('https:') === 0) {
            url = url.replace('https:', '');
        }
        var siteUrl = this.protocollessSiteUrl;
        url = url.replace(siteUrl, '');
        if (environment.locationStrategy === 'path' && '#' === url[0]) {
            url = url.substr(1);
        }
        for (var i = 0; i < (this.allowedLangs || []).length; ++i) {
            var langPrefix = this.allowedLangs[i].lang + '/';
            if (url.indexOf(langPrefix) === 0) {
                url = url.substr((langPrefix || []).length);
                break;
            }
        }
        return url;
    };
    UrlService.prototype.updateLang = function (lng) {
        var path = this.location.path();
        var lang = lng && (lng || []).length
            ? lng
            : (path.indexOf('/') === 0
                ? path.substr(1, 2).toLowerCase()
                : path.substr(0, 2).toLowerCase());
        if (this.isAllowedLang(lang)) {
            this.lang = lang;
        }
        else {
            this.lang = this.defaultLang;
        }
        for (var li = 0; li < (allowedLangs || []).length; ++li) {
            if (allowedLangs[li].lang === this.lang) {
                this.langCode = allowedLangs[li].code;
                break;
            }
        }
    };
    UrlService.prototype.getSlug = function (object) {
        return typeof object.slug !== 'undefined' && typeof object.slug[this.langCode] !== 'undefined' ?
            object.slug[this.langCode] :
            null;
    };
    return UrlService;
}());
export { UrlService };
