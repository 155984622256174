import * as tslib_1 from "tslib";
import { filter } from 'rxjs/operators';
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder } from '@angular/forms';
import { ScrollToService } from 'ng2-scroll-to-el';
import { SugarService } from './model/sugar.service';
import { GlobalService } from './model/global.service';
import { SettingDataService } from './model/setting-data.service';
import { UrlService } from './model/url.service';
import { CmsService } from './model/cms.service';
import { ComponentsService } from './model/components.service';
import { NgwWowService } from 'ngx-wow';
import { UniversalPlatformService } from './shared/platform/universal-platform.service';
import { UniversalRendererHelper } from './shared/platform/universal-renderer.helper';
import { BodyService } from './shared/platform/body.service';
import { MetaData } from './shared/metadata/metadata.service';
import { FbService } from './shared/fb.service';
import { CookiesService } from './shared/cookies.service';
import { AutoUnsubscribe } from './decorators/autounsubscribe.decorator';
import { BehaviorSubject } from 'rxjs';
var AppComponent = /** @class */ (function () {
    function AppComponent(_document, globalService, cmsService, urlService, bodyService, _rendererHelper, _platformService, _sugarService, _componentsService, _router, _platformLocation, _sanitizer, _formBuilder, _settingDataService, _cookiesService, _scrollService, _wowService, _metadata, _fbService) {
        var _this = this;
        this._document = _document;
        this.globalService = globalService;
        this.cmsService = cmsService;
        this.urlService = urlService;
        this.bodyService = bodyService;
        this._rendererHelper = _rendererHelper;
        this._platformService = _platformService;
        this._sugarService = _sugarService;
        this._componentsService = _componentsService;
        this._router = _router;
        this._platformLocation = _platformLocation;
        this._sanitizer = _sanitizer;
        this._formBuilder = _formBuilder;
        this._settingDataService = _settingDataService;
        this._cookiesService = _cookiesService;
        this._scrollService = _scrollService;
        this._wowService = _wowService;
        this._metadata = _metadata;
        this._fbService = _fbService;
        this._routerSubs = [];
        this._router.events.pipe(filter(function (event) { return event instanceof NavigationStart; }))
            .subscribe(function () {
            _this._rendererHelper.removeClass(_this._document.documentElement, 'has-modal');
        });
        this._viewInit = new BehaviorSubject(false);
        this._gsSub = this.globalService.loadGlobalSettings().subscribe(function () {
            var settings = _this.globalService.settings;
            _this._fbService.updateCommonOg();
            _this.insertedTrackingCodes = {};
            if (settings.__loaded) {
                _this.trackingCodesInit();
                _this.structureDataCodesInit();
                _this._metadata.setSiteTitle(_this.globalService.settings.seo.title);
            }
        });
        cmsService.refresh();
        if (_platformService.isBrowser) {
            this._navSub = this._router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).subscribe(function () {
                // Reload WoW animations when done navigating to page,
                // but you are free to call it whenever/wherever you like
                _this._wowService.init();
            });
        }
        this._metadata.setDescription('');
        this._metadata.setKeywords('');
    }
    Object.defineProperty(AppComponent.prototype, "fbService", {
        get: function () {
            return this._fbService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "windowWidth", {
        get: function () {
            return this._platformService.windowWidth;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "bodyClass", {
        get: function () {
            return this.bodyService.bodyClass;
        },
        set: function (className) {
            this.bodyService.bodyClass = className;
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.ngOnInit = function () {
        this.fbService.updateCommonOg();
        if (this.globalService.settings.global.isFullSize === 'true'
            || this.globalService.settings.global.isFullSize === true) {
            this.updateContainer();
        }
        var font = 'Lexend Deca';
        if (font) {
            this.updateFont(font);
        }
        if (this.globalService.settings.global.externalFontsUrl) {
            this.updateFont(this.globalService.settings.global.externalFontsUrl);
        }
    };
    AppComponent.prototype.updateFont = function (font) {
        font = typeof font === 'string' ? (font || '') : '';
        var link = this._document.createElement('link');
        font = font.replace(/,\s/ig, '|')
            .replace(/\s/ig, '+')
            .split('|')
            .map(function (name) { return name + ':200,300,400,500,600,700,800,900'; })
            .join('|');
        link.rel = 'stylesheet';
        link.href = "https://fonts.googleapis.com/css?family=" + font + "&display=swap";
        this._document.head.appendChild(link);
    };
    AppComponent.prototype.createSheet = function () {
        var style = this._document.createElement('style');
        this._document.head.appendChild(style);
        return style;
    };
    AppComponent.prototype.updateContainer = function () {
        var sheet = this.createSheet();
        sheet.innerHTML = '.carousel-home-top.container { max-width: 100% !important }';
    };
    AppComponent.prototype.ngOnDestroy = function () {
        if (this._navSub) {
            this._navSub.unsubscribe();
        }
        if (this._gsSub) {
            this._gsSub.unsubscribe();
        }
        for (var i = 0; i < (this._routerSubs || []).length; ++i) {
            this._routerSubs[i].unsubscribe();
        }
    };
    AppComponent.prototype.ngAfterViewInit = function () {
        this._fbService.initialize();
    };
    AppComponent.prototype.structureDataCodesInit = function () {
        var settings = this.globalService.settings;
        // SEO
        if (settings.__loaded) {
            var seoSettings = typeof settings.seo === 'undefined' ? null : settings.seo;
            if (!seoSettings) {
                return;
            }
            var heads = this._platformService.document.documentElement.getElementsByTagName('head');
            var head = heads && (heads || []).length ? heads[0] : null;
            if (head) {
                if (this._platformService.isServer) {
                    var div = this._platformService.document.createElement('div');
                    if (!this.insertedTrackingCodes.structuredData) {
                        var structureData = this.getStructureDataTrackingCode();
                        if ((structureData || []).length) {
                            div.innerHTML = structureData;
                            while (div.firstChild) {
                                head.appendChild(div.firstChild);
                            }
                            div.innerHTML = '';
                            this.insertedTrackingCodes.structuredData = true;
                        }
                    }
                }
                else if (this._platformService.isBrowser) {
                    var div = this._platformService.document.createElement('div');
                    if (!this.insertedTrackingCodes.structuredData) {
                        var structureData = this.getStructureDataTrackingCode();
                        if ((structureData || []).length) {
                            div.innerHTML = structureData;
                            while (div.firstChild) {
                                head.appendChild(div.firstChild);
                            }
                            div.innerHTML = '';
                            this.insertedTrackingCodes.structuredData = true;
                        }
                    }
                }
            }
        }
    };
    AppComponent.prototype.trackingCodesInit = function () {
        var _this = this;
        var settings = this.globalService.settings;
        // SEO
        if (settings.__loaded) {
            var seoSettings = typeof settings.seo === 'undefined' ? null : settings.seo;
            if (!seoSettings) {
                return;
            }
            var heads = this._platformService.document.documentElement.getElementsByTagName('head');
            var head = heads && (heads || []).length ? heads[0] : null;
            var body = this.bodyService.body;
            if (head && this._platformService.isServer) {
                var div = this._platformService.document.createElement('div');
                if (!this.insertedTrackingCodes.fbPixel) {
                    var fbPixelCode = this.getTrackingCode('fb_pixel_code');
                    if ((fbPixelCode || []).length) {
                        div.innerHTML = fbPixelCode;
                        while (div.firstChild) {
                            head.appendChild(div.firstChild);
                        }
                        div.innerHTML = '';
                        this._rendererHelper.setAttribute(this._platformService.document.documentElement, 'data-has-fb-pixel', '1');
                        this.insertedTrackingCodes.fbPixel = true;
                    }
                }
                if (!this.insertedTrackingCodes.ga) {
                    var gaCode = this.getTrackingCode('ga_code');
                    if ((gaCode || []).length) {
                        div.innerHTML = gaCode;
                        while (div.firstChild) {
                            head.appendChild(div.firstChild);
                        }
                        div.innerHTML = '';
                        this._rendererHelper.setAttribute(this._platformService.document.documentElement, 'data-has-ga', '1');
                        this.insertedTrackingCodes.ga = true;
                    }
                }
                if (!this.insertedTrackingCodes.gtm) {
                    var gtmHeadCode = this.getTrackingCode('gtm_code_head');
                    var gtmBodyCode = this.getTrackingCode('gtm_code_body');
                    if ((gtmHeadCode || []).length && (gtmBodyCode || []).length) {
                        div.innerHTML = gtmHeadCode;
                        while (div.firstChild) {
                            head.appendChild(div.firstChild);
                        }
                        div.innerHTML = '';
                        div.innerHTML = gtmBodyCode;
                        var insertedFirst = false;
                        while (div.firstChild) {
                            if (!insertedFirst) {
                                body.insertBefore(div.firstChild, body.firstChild);
                                insertedFirst = true;
                            }
                            else {
                                body.insertBefore(div.firstChild, body.firstChild.nextSibling); // coś jak insertAfter()
                            }
                        }
                        div.innerHTML = '';
                        this._rendererHelper.setAttribute(this._platformService.document.documentElement, 'data-has-gtm', '1');
                        this.insertedTrackingCodes.gtm = true;
                    }
                }
            }
            else if (head && this._platformService.isBrowser) {
                var range = this._platformService.document.createRange();
                range.selectNode(body); // required in Safari
                this.insertedTrackingCodes.fbPixel = this.insertedTrackingCodes.fbPixel ||
                    this._rendererHelper.hasAttribute(this._platformService.document.documentElement, 'data-has-fb-pixel');
                if (!this.insertedTrackingCodes.fbPixel) {
                    var fbPixelCode = this.getTrackingCode('fb_pixel_code');
                    if ((fbPixelCode || []).length) {
                        var fbFragm = range.createContextualFragment(fbPixelCode);
                        head.appendChild(fbFragm);
                        this._rendererHelper.setAttribute(this._platformService.document.documentElement, 'data-has-fb-pixel', '1');
                        this.insertedTrackingCodes.fbPixel = true;
                    }
                }
                this.insertedTrackingCodes.ga = this.insertedTrackingCodes.ga ||
                    this._rendererHelper.hasAttribute(this._platformService.document.documentElement, 'data-has-ga');
                if (!this.insertedTrackingCodes.ga) {
                    var gaCode = this.getTrackingCode('ga_code');
                    if ((gaCode || []).length) {
                        var gaFragm = range.createContextualFragment(gaCode);
                        head.appendChild(gaFragm);
                        this._routerSubs.push(this._router.events.subscribe(function (evt) {
                            if (evt instanceof NavigationEnd) {
                                if (typeof _this._platformService.window['ga'] !== 'undefined') {
                                    _this._platformService.window['ga']('set', 'page', evt.urlAfterRedirects);
                                    _this._platformService.window['ga']('send', 'pageview');
                                }
                                else if (typeof _this._platformService.window['dataLayer'] !== 'undefined') {
                                    _this._platformService.window['dataLayer'].push({
                                        event: 'pageview',
                                        url: evt.urlAfterRedirects,
                                    });
                                }
                            }
                        }));
                        this._rendererHelper.setAttribute(this._platformService.document.documentElement, 'data-has-ga', '1');
                        this.insertedTrackingCodes.ga = true;
                    }
                }
                this.insertedTrackingCodes.gtm = this.insertedTrackingCodes.gtm ||
                    this._rendererHelper.hasAttribute(this._platformService.document.documentElement, 'data-has-gtm');
                if (!this.insertedTrackingCodes.gtm) {
                    var gtmHeadCode = this.getTrackingCode('gtm_code_head');
                    var gtmBodyCode = this.getTrackingCode('gtm_code_body');
                    if ((gtmHeadCode || []).length && (gtmBodyCode || []).length) {
                        var gtmHeadFragm = range.createContextualFragment(gtmHeadCode);
                        head.appendChild(gtmHeadFragm);
                        var gtmBodyFragm = range.createContextualFragment(gtmBodyCode);
                        body.insertBefore(gtmBodyFragm, body.firstChild);
                        this._routerSubs.push(this._router.events.subscribe(function (evt) {
                            if (evt instanceof NavigationEnd && typeof _this._platformService.window['dataLayer'] !== 'undefined') {
                                _this._platformService.window['dataLayer'].push({
                                    event: 'pageview',
                                    url: evt.urlAfterRedirects,
                                });
                            }
                        }));
                        this._rendererHelper.setAttribute(this._platformService.document.documentElement, 'data-has-gtm', '1');
                        this.insertedTrackingCodes.gtm = true;
                    }
                }
            }
        }
    };
    AppComponent.prototype.getTrackingCode = function (name) {
        if (!this.globalService.settings.__loaded) {
            return '';
        }
        var seoSettings = this.globalService.settings.seo;
        var code;
        if (typeof seoSettings[name] === 'undefined') {
            code = '';
        }
        else if (typeof seoSettings[name] === 'string') {
            code = seoSettings[name];
        }
        else {
            code = seoSettings[name][this.urlService.lang];
        }
        return code;
    };
    AppComponent.prototype.getStructureDataTrackingCode = function () {
        if (!this.globalService.settings.__loaded) {
            return '';
        }
        var structured_data = this.globalService.settings.seo.structured_data;
        var code;
        if (typeof structured_data === 'undefined') {
            code = '';
        }
        else if (typeof structured_data === 'string') {
            code = structured_data;
        }
        else {
            code = structured_data[this.urlService.lang];
        }
        return code;
    };
    AppComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [Object, GlobalService,
            CmsService,
            UrlService,
            BodyService,
            UniversalRendererHelper,
            UniversalPlatformService,
            SugarService,
            ComponentsService,
            Router,
            PlatformLocation,
            DomSanitizer,
            FormBuilder,
            SettingDataService,
            CookiesService,
            ScrollToService,
            NgwWowService,
            MetaData,
            FbService])
    ], AppComponent);
    return AppComponent;
}());
export { AppComponent };
