import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ObjectUtils } from '../shared/utils/object-utils';
import { environment } from '../../environments/environment';
import { ApiRouting, ApiRoutingItem, AppRoutingMapItem, routingBaseMap, RoutingItem, RoutingMap, RoutingMapItemChildren } from './routing';
import { HttpClient } from '@angular/common/http';

// mechanizm wypalarki nie respektuje zmian ustawień dostępnych języków - obejście:
// export const allowedLangs = environment.langs.allowed;
export const allowedLangs = [
  { lang: 'pl', code: 'pl_pl' },
  { lang: 'en', code: 'en_us' },
];

@Injectable()
export class RoutingDataService {

  protected routingData: RoutingMap;

  protected data: BehaviorSubject<RoutingMap>;

  constructor(
    protected http: HttpClient,
  ) {
    this.routingData = [];
    this.data = new BehaviorSubject(this.routingData);

    this.data.subscribe((routing) => {
      this.routingData = routing;
    });
  }

  get routing(): RoutingMap {
    return this.routingData;
  }

  get isEmpty(): boolean {
    return (this.routingData || []).length === 0;
  }

  getRouting(force = false): BehaviorSubject<RoutingMap> {
    if (!this.isEmpty && !force) {
      return this.data;
    }

    return this.refresh();
  }

  refresh(): BehaviorSubject<RoutingMap> {
    const url = environment.apiHost + '/routing';
    const params = {};

    this.http.get<any>(url, params).pipe(
      map(response => {
        return this.createRoutes(response.data);
      }),
      catchError((error: Response | any): Observable<any> => {
        return this.handleError(error, url, params);
      }),
    )
      .subscribe(routing => {
        this.data.next(routing);
      });

    return this.data;
  }

  handleError(error: Response | any, url: string, params: { [key: string]: any } | null): Observable<any> {
    let errorMessage: any;

    if (error.status === 0) {
      errorMessage = {
        success: false,
        status: 0,
        data: 'Sorry, there has an connection error occurred while trying to get routing configuration. Please try again.',
        url: url,
        params: params,
      };
    } else if (error.json) {
      errorMessage = error;
    } else {
      errorMessage = {
        success: false,
        status: error.status,
        data: error.message,
        url: url,
        params: params,
      };
    }

    return observableThrowError(errorMessage);
  }

  protected createRoutes(routingData: ApiRouting): RoutingMap {
    const routes: RoutingMap = [];

    const getChildrenTypes = (children: RoutingMapItemChildren) => {
      const types = [];

      for (let j = 0; j < (children || []).length; ++j) {
        types.push(children[j].type);
      }

      return types;
    };

    let i = 0;
    let apiItem: ApiRoutingItem;
    let routingItem: RoutingItem;
    const parentsChildren = [];

    const hasItems = {
      searchResults: false,
      artists: false,
      auctions: false,
      exhibitions: false,
      news: false,
      products: false,
    };

    // poszukiwania niezbędnych, choć prawdopodobnie brakujących routes
    for (let rdsi = 0; rdsi < (routingData || []).length; ++rdsi) {
      apiItem = routingData[rdsi];

      // wyniki wyszukiwania
      if (apiItem.type === 'search-results') {
        hasItems.searchResults = true;
      } else if (typeof hasItems[apiItem.type] !== 'undefined') { // pozostałe
        hasItems[apiItem.type] = true;
      }
    }

    // uzupełnienia: wyniki wyszukiwania
    if (!hasItems.searchResults) {
      const slugs: { [key: string]: string } = {};
      const possibleSlugs = {
        pl: 'wyniki-wyszukiwania',
        en: 'search-results',
      };

      // mechanizm wypalarki nie respektuje zmian ustawień dostępnych języków
      // for (let i = 0; i < (environment.langs.allowed || []).length; ++i) {
      //   this.langsAllowed.push(environment.langs.allowed[i].lang);
      // }
      // obejście:
      for (let li = 0; li < (allowedLangs || []).length; ++li) {
        const lang = allowedLangs[li].lang;

        slugs[lang] = typeof possibleSlugs[lang] === 'undefined'
          ? possibleSlugs.en
          : possibleSlugs[lang];
      }

      const searchRoute = {
        slugs: slugs,
        type: 'search-results',
      };

      routingData.push(searchRoute);
    }

    // uzupełnienia: routing potomny
    const hasChildren = ['artists', 'auctions', 'exhibitions', 'news', 'products'];
    let cProp: string;
    let childSlugs: { [key: string]: string };
    let childRoute: ApiRoutingItem;
    let parentRoute: ApiRoutingItem;

    for (let hci = 0; hci < (hasChildren || []).length; ++hci) {
      cProp = hasChildren[hci];

      if (typeof hasItems[cProp] === 'undefined') {
        continue;
      }

      childRoute = ObjectUtils.arrayFindFirstObject(routingData, 'type', cProp + '-single');

      if (childRoute) {
        continue;
      }

      parentRoute = ObjectUtils.arrayFindFirstObject(routingData, 'type', cProp);

      if (!parentRoute) {
        continue;
      }

      childSlugs = {};

      for (let li = 0; li < (environment.langs.allowed || []).length; ++li) {
        const lang = environment.langs.allowed[li].lang;
        childSlugs[lang] = cProp + '-single';
      }

      childRoute = {
        slugs: childSlugs,
        type: cProp + '-single',
      };

      routingData.push(childRoute);
    }

    for (; i < (routingData || []).length; ++i) {
      apiItem = ObjectUtils.copy(routingData[i]);

      let ii = 0;
      let routingBaseItem: AppRoutingMapItem;
      let routingMapItem: AppRoutingMapItem;

      for (; ii < (routingBaseMap || []).length; ++ii) {
        routingBaseItem = routingBaseMap[ii];

        if (routingBaseItem.type === apiItem.type) {
          routingMapItem = routingBaseItem;
          break;
        }

        if (routingBaseItem.children) {
          for (let ci = 0; ci < (routingBaseItem.children || []).length; ++ci) {

            if (routingBaseItem.children[ci].type === apiItem.type) {
              routingMapItem = {
                type: apiItem.type,
                module: routingBaseItem.module,
                segment: routingBaseItem.children[ci].segment,
              };
              break;
            }

          }
        }
      }

      if (!routingMapItem) {
        continue;
      }

      routingItem = {
        type: apiItem.type,
        slugs: apiItem.slugs,
        module: routingMapItem.module,
      };

      if (routingMapItem.children) {
        routingItem.children = routingMapItem.children;

        parentsChildren.push({
          parent: routingMapItem.type,
          children: routingMapItem.children,
          childrenTypes: getChildrenTypes(routingMapItem.children),
        });
      }

      routes.push(routingItem);
    }

    for (i = 0; i < (parentsChildren || []).length; ++i) {
      const pcItem = parentsChildren[i];

      for (let ii = 0; ii < (routes || []).length; ++ii) {
        if (pcItem.childrenTypes.indexOf(routes[ii].type) > -1) {
          routes[ii].parent = pcItem.parent;
        }
      }
    }

    this.routingData = routes;
    console.log(routes);
    return routes;
  }

}
